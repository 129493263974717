import { graphql } from "gatsby"
import React from 'react'
import Layout from "../components/layout"
import Repertoire from "../components/Repertoire/Repertoire"

const RepertoireTmp = ({data: { markdownRemark: {frontmatter: {repertoire, chamber}}}}) => (
  <Layout>
    <Repertoire repertoire={repertoire} chamber={chamber} />
  </Layout>
)

export default RepertoireTmp

export const pageQuery = graphql`
  query repertoire($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        repertoire {
          title
          pieceOfMusic {
            author
            title
            addInfo 
            premiere
          }
        }
        chamber {
          title
          repertoire {
            title
            pieceOfMusic {
              author
              title
              addInfo 
              premiere
            }
          }
        }
      }
    }
  }
`

