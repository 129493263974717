import React from "react"
import classnames from "classnames"
import Title from "../Title/Title"
import PieceOfMusic from "./PieceOfMusic/PieceOfMusic"

const SubPepertoire = ({ repertoire, subtitles }) => (
  <>
    {repertoire.map(({ title, pieceOfMusic }) => {
      let auth = ""
      return (
        <div key={title} className="m-t-lg">
          <Title level={subtitles ? 4 : 3} className={classnames({
            'has-text-centered': !subtitles,
            'has-text-right': subtitles,
          })}>
            {title}
          </Title>

          {pieceOfMusic.map(pom => {
            let author = ""
            if (pom.author !== auth) {
              auth = pom.author
              author = pom.author
            }

            return (
              <PieceOfMusic
                key={`${pom.author}${pom.title}`}
                pieceOfMusic={{
                  ...pom,
                  author,
                }}
              />
            )
          })}
        </div>
      )
    })}
  </>
)

const Repertoire = ({ chamber, repertoire }) => {
  return (
    <div>
      <Title>Repertoire</Title>
      <SubPepertoire repertoire={repertoire} />

      <Title level={3} className="has-text-centered m-t-lg">
        {chamber.title}
      </Title>

      <SubPepertoire repertoire={chamber.repertoire} subtitles />

    </div>
  )
}

export default Repertoire
