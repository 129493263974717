import React from "react"
import classnames from "classnames"
import './PieceOfMusic.scss'

const PieceOfMusic = ({ pieceOfMusic }) => {
  return (
    <div className="PieceOfMusic columns is-marginless is-mobile">
      <div className="column is-half has-text-right p-none p-r-sm has-text-weight-bold">
        {pieceOfMusic.author}
      </div>
      <div className="PieceOfMusic__Right column is-paddingless p-none p-l-sm is-flex">
        <div>
          {pieceOfMusic.title}{" "}
          <span className="help">{pieceOfMusic.addInfo}</span>
        </div>
        {pieceOfMusic.premiere && (
          <span className={classnames("tag is-rounded is-small m-l-sm", {
            'is-danger': pieceOfMusic.premiere === 'world',
            'is-warning': pieceOfMusic.premiere === 'russian',
          })}>
            {pieceOfMusic.premiere} premiere
          </span>
        )}
      </div>
    </div>
  )
}

export default PieceOfMusic
